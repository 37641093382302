import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons"
import produce from "immer"
import { isMobile } from "react-device-detect";

import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader";
import SEO from "../components/seo"
import GoogleImage from "../components/GoogleImage"
import Button from "../components/buttons/HoverButton"
import MajorError from "../components/MajorError"
import SelectField from "../components/fields/SelectField"

import { CartContext } from '../state/CartContext';
import { devices, colors } from "../settings"
import Layout2Columns from "../components/layout/Layout2Columns";

const Content = styled.div`
    margin-left: 25xpx;

    @media ${devices.mobile} {
        margin-left: 0px;
    } 
`
const Sizes = styled.ul`
    li {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    
        select {
          width: 60px;
          flex: 0;
          margin: 0px 10px;
        }

        .cost {
            color: ${colors.green};
            width: 60px;
            text-align: center;
        }
    }
`

export default function Extensions() {
    const data = useStaticQuery(graphql`
        {
            accessories: allMysqlAccesories(filter: {name: {eq: "ezlit-extensions"}}) {
                edges {
                    accessory: node {
                        id
                        name
                        title
                    }
                }
            }
            sizes: allMysqlAccesorySizes(filter: {name: {eq: "ezlit-extensions"}}) {
                edges {
                  size: node {
                    id
                    size_id
                    accessory_id
                    size
                    cost
                  }
                }
            }
        }
    `);

    const cart = useContext(CartContext);

    const accessory = data.accessories.edges[0].accessory;
    const sizes = data.sizes.edges;

    const [quantity, setQuantity] = useState({})
    const [adding, setAdding] = useState(null);
    const [error, setError] = useState(null);

    useEffect(
        () => {
            let values = {}

            for (const size of sizes) {
                values["size" + size.size.size_id] = 1;
            }
            
            setQuantity(values)
        }, 
        []
    );

    function addAccessory(accessorySize) {
        setAdding(accessorySize.id);

        cart.addAccessoryToCart(accessorySize.size_id, quantity["size" + accessorySize.size_id] || 1, null, (cart) => {
            if (cart) {
                navigate("/checkout/")
            }
            else {
                setError("An error occured adding the accessory to your cart.")
            }

            setAdding(null);
        })
    }

    const quantities = [1,2,3,4,5,6,7,8,9,10]

    return (
        <>
            <SEO page="accessory" title={accessory.title} />

            <Page 
                breadcrumb={[
                    {title: "Home", url:"/"},
                    {title: "Products", url:"/products/"},
                    {title: "Accessories", url:"/products/accessories/"},                    
                    {title: accessory.title, url: null}
                ]}
            >
                    <PageHeader title={accessory.title} />
                    <Content>
                        <p>Extension cables for when you need to lengthen the cables included with your sign.</p>
                        <p>Our EZLit Plug-n-Play Channel Letters installation system is ready to install when you receive it. In the special case that you would need to lengthen the cables included with your sign. We sell extension is various length for this purpose.</p>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <GoogleImage src="ul-logo-black.png" alt="UL Logo" required lazy style={{width: "100px", height: "35px", marginRight:"10px"}} width={100} height={35} />{' '}
                            All our signs carry the UL label and are tested and inspected by a UL certified inspector.
                        </div>

                        <br />

                        <Layout2Columns align="top" left={35} right={65}>
                            <div>
                                <GoogleImage src="SignTypes/EZLit-Extension-SignMonkey.jpg" required lazy alt="EZLit Extension Cable" width={isMobile ? 355:404} height={252} />
                            </div>
                            <div>
                                <Sizes>
                                    {sizes.map(({size}) => (
                                        <li key={size.id}>
                                            <span dangerouslySetInnerHTML={{__html: size.size}} />
                                            &nbsp;                      
                                            <span className="cost">${size.cost}</span>
                                            &nbsp;
                                            <SelectField 
                                                label="Quantity"
                                                value={quantity["size" + size.size_id]}
                                                options={quantities.map((quantity) => {
                                                    return {value:quantity, label:quantity};
                                                })}
                                                onChange={(e) => {
                                                    e.persist();

                                                    setQuantity(produce(draft => {
                                                        draft["size" + size.size_id] = e.target.value;
                                                    }));
                                                }}
                                            >
                                                <Button 
                                                    label="Add To Cart"
                                                    color="green"
                                                    icon={faShoppingCart}
                                                    busy={adding == size.id}
                                                    disabled={adding == size.id}
                                                    onClick={() => addAccessory(size)}
                                                />                                                  
                                            </SelectField>
                                        </li>
                                    ))}
                                </Sizes>
                            </div>
                        </Layout2Columns>
                    </Content>
                    
                    { error &&
                        <MajorError 
                            error={error} 
                            log={error}
                            onClose={() => setError(null)}
                        />
                    }
            </Page>
        </>
    )
}